import { IComponentController } from '@wix/editor-elements-types';

const mapActionsToProps: IComponentController = ({ updateProps }) => {
  return {
    onValueChange: (value?: Date) => {
      updateProps({
        value,
      });
    },
    isToggle: (isOpen: boolean) => {
      updateProps({
        isOpen,
      });
    },
    discardDefaultValue: () => {
      updateProps({
        useTodayAsDefaultValue: false,
      });
    },
  };
};

export default mapActionsToProps;
