import * as React from 'react';
import classNames from 'classnames';
import {
  IDatePickerImperativeActions,
  IDatePickerCalendarProps,
  IDatePickerBaseProps,
} from '../DatePicker.types';
import { areDatesEqual, getStartOfDay } from '../../../core/commons/dateUtils';
import { getFormattedDate, getAllTranslations } from '../utils';
import {
  HAS_CUSTOM_FOCUS_CLASSNAME,
  keyCodes,
} from '../../../core/commons/a11y';
import DatePickerCalendar from '../../DatePickerCalendar/viewer/DatePickerCalendar';
import style from './styles/DatePicker.scss';
import { ReactComponent as CalendarIcon } from './assets/calendar.svg';

const noop = () => {};

const DatePickerBase: React.ForwardRefRenderFunction<
  IDatePickerImperativeActions,
  IDatePickerBaseProps
> = (props, ref) => {
  const {
    translate,
    id,
    skin,
    label,
    placeholder,
    dateFormat = 'YYYY/MM/DD',
    readOnly,
    required,
    isDisabled,
    useTodayAsDefaultValue,

    minDate,
    maxDate,
    allowPastDates,
    allowFutureDates,
    disabledDates = [],
    disabledDaysOfWeek = [],
    weekStartDay = 0,

    isValid,
    shouldShowValidityIndication,
    validateValueAndShowIndication = noop,
    onBlur = noop,
    onFocus = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onChange = noop,

    NavbarComponent,
    isCompactMode,
    value: valueFromProps,
    onValueChange = noop,
    isOpen,
    isToggle = noop,
    discardDefaultValue = noop,

    externallyOpenCalendar,
    externallyCloseCalendar,
  } = props;

  // Temp fix for FSM-4293
  const [tempState, setTempState] = React.useState(true);

  const value = React.useMemo(
    () =>
      typeof valueFromProps === 'string'
        ? new Date(valueFromProps)
        : valueFromProps,
    [valueFromProps],
  );

  React.useEffect(() => {
    if (useTodayAsDefaultValue && !value) {
      const date = getStartOfDay(new Date(Date.now()));
      onValueChange(date);
      validateValueAndShowIndication({
        value: date,
      });
      discardDefaultValue();

      // Temp fix for FSM-4293
      setTempState(!tempState);
    }
  }, [
    onValueChange,
    validateValueAndShowIndication,
    discardDefaultValue,
    useTodayAsDefaultValue,
    value,
    tempState,
  ]);

  const translations = React.useMemo(() => getAllTranslations(translate), [
    translate,
  ]);

  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [shouldDiscardFocusEvent, setShouldDiscardFocusEvent] = React.useState<
    boolean
  >(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
        setIsFocused(true);
      },
      blur: () => {
        inputRef.current?.blur();
        setIsFocused(false);
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          inputRef.current?.setCustomValidity(message.message);
        }
      },
      getValidationMessage: () => {
        return inputRef.current && inputRef.current.validationMessage;
      },
    };
  });

  const _onClick: React.MouseEventHandler<HTMLElement> = event => {
    if (isDisabled) {
      return;
    }
    onClick(event);
    _onFocus(({
      ...event,
      type: 'focus',
    } as any) as React.FocusEvent<HTMLInputElement>);

    if (isOpen) {
      closeCancelCalendar();
    } else {
      openCalendar();
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const _onFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    if (isDisabled || readOnly) {
      return;
    }
    setIsFocused(true);

    if (shouldDiscardFocusEvent) {
      setShouldDiscardFocusEvent(false);
    } else {
      onFocus(event);
      if (!isOpen) {
        openCalendar();
      }
    }
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setIsFocused(false);
    onBlur(event);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.keyCode === keyCodes.enter || event.keyCode === keyCodes.space) {
      if (!isOpen) {
        openCalendar();
      }
      event.preventDefault();
    }
    if (event.keyCode === keyCodes.escape && isOpen) {
      closeCancelCalendar();
    }
  };

  const getPropsForCalendar = (): IDatePickerCalendarProps => ({
    t: translations,
    id: `calendar_${id}`,
    skin,
    value,
    minDate,
    maxDate,
    allowPastDates,
    allowFutureDates,
    disabledDates,
    disabledDaysOfWeek,
    weekStartDay,
    isCompactMode,
    inputWrapperRef: wrapperRef,
    onApply: closeApplyCalendar,
    onCancel: closeCancelCalendar,
    onClick,
    onDblClick,
    NavbarComponent,
    onMouseEnter,
    onMouseLeave,
  });

  const openCalendar = () => {
    if (isDisabled || readOnly) {
      return;
    }
    isToggle(true);
    setIsFocused(true);

    externallyOpenCalendar?.();
  };

  const closeApplyCalendar = (newValue: Date) => {
    if (!value || !areDatesEqual(newValue, value)) {
      onValueChange(newValue);
      validateValueAndShowIndication({
        value: newValue,
      });
      onChange({
        type: 'change',
      } as React.ChangeEvent);
    }
    closeCancelCalendar();
  };

  const closeCancelCalendar = (focusInput: boolean = true) => {
    isToggle(false);
    if (focusInput) {
      // setTimeout because of bug in iOS ECL-674
      setTimeout(() => {
        setShouldDiscardFocusEvent(true);
        inputRef.current?.focus();
      }, 0);
    } else {
      setIsFocused(false);
    }
    externallyCloseCalendar?.();
  };

  const containerClasses = classNames(style[skin], {
    [style.requiredIndication]: required,
    [style.invalid]: !!shouldShowValidityIndication && !isValid,
    [style.disabled]: isDisabled,
    [style.readonly]: readOnly,
    [style.focused]: isFocused || isOpen,
  });

  const stringValue = value ? getFormattedDate(value, dateFormat) : '';

  return (
    <div id={id} className={containerClasses}>
      <div
        data-testid="wrapper"
        ref={wrapperRef}
        className={style.labelWrapper}
        onClick={_onClick}
        onDoubleClick={_onDblClick}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
      >
        <label htmlFor={`input_${id}`} className={style.label}>
          {label}
        </label>
        <div className={style.inputWrapper}>
          <input
            ref={inputRef}
            id={`input_${id}`}
            type="text"
            className={classNames(style.input, HAS_CUSTOM_FOCUS_CLASSNAME)}
            value={stringValue}
            onChange={() => {}}
            onBlur={_onBlur}
            onFocus={_onFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
            disabled={isDisabled}
          />
          <CalendarIcon></CalendarIcon>
        </div>
      </div>
      {isOpen && (
        <DatePickerCalendar {...getPropsForCalendar()}></DatePickerCalendar>
      )}
    </div>
  );
};

export default React.forwardRef(DatePickerBase);
