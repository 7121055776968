const YEARS_LIST_RANGE = {
  min: 1900,
  max: 2100,
} as const;
export const YEARS_LIST = Array(YEARS_LIST_RANGE.max - YEARS_LIST_RANGE.min + 1)
  .fill(0)
  .map((_, ind) => YEARS_LIST_RANGE.min + ind);

export const DAY_NAMES = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const MONTH_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const;

export const DEFAULT_TRANSLATIONS = {
  previousMonthNav: 'Previous Month',
  nextMonthNav: 'Next Month',
  previousYearNav: 'Previous Year',
  nextYearNav: 'Next Year',
} as const;

export const SKIN_NAMES = [
  'DatePickerDefaultSkin',
  'DatePickerTextBetweenNavSkin',
  'DatePickerTextYearNavSkin',
] as const;
