import * as React from 'react';
import classNames from 'classnames';
import style from '../styles/Month.scss';
import { ICalendarMonthProps, DayName } from '../../DatePickerCalendar.types';
import {
  getGridOfDaysInMonth,
  areDatesEqual,
  getStartOfDay,
} from '../../../../core/commons/dateUtils';

const DayHeaderCell: React.FC<{ dayName: DayName }> = ({ dayName }) => {
  return (
    <th key={dayName.shortName} role="columnheader">
      <span aria-hidden="true">{dayName.shortName}</span>
      <span data-testid="sr-only" className={style.srOnly}>
        {dayName.longName}
      </span>
    </th>
  );
};

type IDayCellProps = Pick<
  ICalendarMonthProps,
  | 'value'
  | 'allowPastDates'
  | 'allowFutureDates'
  | 'disabledDaysOfWeek'
  | 'year'
  | 'month'
  | 'onDayChange'
> & {
  day: number | undefined;
  minDate?: Date;
  maxDate?: Date;
  todayDate: Date;
  disabledDates: Array<Date>;
};

const DayCell: React.FC<IDayCellProps> = props => {
  const {
    value,
    minDate,
    maxDate,
    todayDate,
    allowPastDates,
    allowFutureDates,
    disabledDaysOfWeek,
    disabledDates,
    year,
    month,
    onDayChange,
    day,
  } = props;

  if (!day) {
    return <td></td>;
  }

  const date = new Date(year, month, day);
  const dayOfWeek = date.getDay();

  const isDisabled =
    disabledDates.some(disabledDate => areDatesEqual(disabledDate, date)) ||
    disabledDaysOfWeek.some(disabledDay => disabledDay === dayOfWeek) ||
    (!allowPastDates && date < todayDate) ||
    (!allowFutureDates && date > todayDate) ||
    (!!minDate && date < new Date(minDate)) ||
    (!!maxDate && date > new Date(maxDate));
  const isSelected = value && areDatesEqual(value, date);
  const isToday = areDatesEqual(date, todayDate);

  const tdClassNames = classNames({
    [style.disabled]: isDisabled,
    [style.selected]: isSelected,
    [style.today]: isToday,
  });

  return (
    <td
      data-testid={isToday ? 'today' : undefined}
      className={tdClassNames}
      aria-selected={isSelected}
    >
      <button onClick={() => onDayChange(day)} disabled={isDisabled}>
        <span className={style.text}>{day}</span>
      </button>
    </td>
  );
};

const Month: React.FC<ICalendarMonthProps> = props => {
  const {
    t,
    value: valueFromProps,
    minDate,
    maxDate,
    allowPastDates,
    allowFutureDates,
    disabledDates,
    disabledDaysOfWeek,
    weekStartDay,
    year,
    month,
    onDayChange,
  } = props;

  const value = React.useMemo(
    () =>
      typeof valueFromProps === 'string'
        ? new Date(valueFromProps)
        : valueFromProps,
    [valueFromProps],
  );

  const shiftedDayNames = [
    ...t.dayNames.slice(weekStartDay),
    ...t.dayNames.slice(0, weekStartDay),
  ];

  const dayHeaders = shiftedDayNames.map((dayName, index) => (
    <DayHeaderCell key={index} dayName={dayName}></DayHeaderCell>
  ));

  const memoizedDisabledDates = React.useMemo(
    () => disabledDates.map(date => new Date(date)),
    [disabledDates],
  );

  const todayDate = getStartOfDay(new Date(Date.now()));
  const minDateAsDate = minDate ? new Date(minDate) : undefined;
  const maxDateAsDate = maxDate ? new Date(maxDate) : undefined;

  const daysGrid = React.useMemo(
    () => getGridOfDaysInMonth(year, month, weekStartDay),
    [year, month, weekStartDay],
  );

  const days = daysGrid.map((week, weekIndex) => (
    <tr key={weekIndex} role="row">
      {week.map((_day, index) => (
        <DayCell
          key={index}
          year={year}
          month={month}
          day={_day}
          value={value}
          allowPastDates={allowPastDates}
          allowFutureDates={allowFutureDates}
          disabledDaysOfWeek={disabledDaysOfWeek}
          onDayChange={onDayChange}
          minDate={minDateAsDate}
          maxDate={maxDateAsDate}
          todayDate={todayDate}
          disabledDates={memoizedDisabledDates}
        ></DayCell>
      ))}
    </tr>
  ));

  return (
    <table data-testid="month" role="table" className={style.month}>
      <thead>
        <tr>{dayHeaders}</tr>
      </thead>
      <tbody>{days}</tbody>
    </table>
  );
};

export default Month;
