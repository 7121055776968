import * as React from 'react';
import classNames from 'classnames';
import { ReactComponent as SingleSideArrowIcon } from '../assets/singleSideArrow.svg';
import { ReactComponent as DoubleSideArrowIcon } from '../assets/doubleSideArrow.svg';
import { ICalendarNavbarWithSkinProps } from '../../DatePickerCalendar.types';

const Navbar: React.FC<ICalendarNavbarWithSkinProps> = props => {
  const {
    t,
    skin,
    isCompactMode,
    year,
    month,
    onYearChange,
    onMonthChange,
    onCurrentYearClick,
    style,
  } = props;

  const monthName =
    t.monthNames[month][isCompactMode ? 'shortName' : 'longName'];

  const yearButtonContent = (prev: boolean) => {
    switch (skin) {
      case 'DatePickerTextBetweenNavSkin':
        return <SingleSideArrowIcon></SingleSideArrowIcon>;
      case 'DatePickerTextYearNavSkin':
        return year + (prev ? -1 : 1);
      default:
        return <DoubleSideArrowIcon></DoubleSideArrowIcon>;
    }
  };

  const prevYearButton = (
    <button
      data-testid="prevYear"
      autoFocus={true}
      aria-label={t.previousYearNav}
      className={classNames([style.navbtn, style.year, style.prev])}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onYearChange(year - 1);
        e.preventDefault();
      }}
    >
      {yearButtonContent(true)}
    </button>
  );

  const nextYearButton = (
    <button
      data-testid="nextYear"
      aria-label={t.nextYearNav}
      className={classNames([style.navbtn, style.year, style.next])}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onYearChange(year + 1);
        e.preventDefault();
      }}
    >
      {yearButtonContent(false)}
    </button>
  );

  const prevMonthButton = (
    <button
      data-testid="prevMonth"
      aria-label={t.previousMonthNav}
      className={classNames([style.navbtn, style.month, style.prev])}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onMonthChange(month - 1);
        e.preventDefault();
      }}
    >
      <SingleSideArrowIcon></SingleSideArrowIcon>
    </button>
  );

  const nextMonthButton = (
    <button
      data-testid="nextMonth"
      aria-label={t.nextMonthNav}
      className={classNames([style.navbtn, style.month, style.next])}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        onMonthChange(month + 1);
        e.preventDefault();
      }}
    >
      <SingleSideArrowIcon></SingleSideArrowIcon>
    </button>
  );

  const splitNavbar = () => (
    <>
      <div className={style.monthWrapper}>
        {prevMonthButton}
        <div data-testid="currentMonth" className={style.currentMonth}>
          {monthName}
        </div>
        {nextMonthButton}
      </div>
      <div className={style.yearWrapper}>
        {prevYearButton}
        <button
          data-testid="currentYear"
          className={style.currentYear}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            onCurrentYearClick();
            e.preventDefault();
          }}
        >
          {year}
        </button>
        {nextYearButton}
      </div>
    </>
  );

  const joinedNavbar = () => (
    <>
      {prevYearButton}
      {prevMonthButton}
      <button
        data-testid="currentMonthYear"
        className={style.currentMonthYear}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          onCurrentYearClick();
          e.preventDefault();
        }}
      >
        {`${monthName} ${year}`}
      </button>
      {nextMonthButton}
      {nextYearButton}
    </>
  );

  const content =
    skin === 'DatePickerTextBetweenNavSkin' ? splitNavbar() : joinedNavbar();

  return (
    <div data-testid="navbar" className={style.navbar}>
      {content}
    </div>
  );
};

export default Navbar;
