import { DatePickerDateFormat } from '@wix/thunderbolt-components-native';
import { TranslationGetter } from '@wix/editor-elements-types';
import { DAY_NAMES, DEFAULT_TRANSLATIONS, MONTH_NAMES } from './constants';
import {
  IDatePickerTranslations,
  DefaultTranslations,
} from './DatePicker.types';

export const getFormattedDate = (
  date: Date,
  format: DatePickerDateFormat,
): string => {
  const yyyy = `${date.getFullYear()}`;
  const m = `${date.getMonth() + 1}`;
  const d = `${date.getDate()}`;
  const mm = m.padStart(2, '0');
  const dd = d.padStart(2, '0');
  switch (format) {
    case 'DD/MM/YYYY':
      return `${dd}/${mm}/${yyyy}`;
    case 'MM/DD/YYYY':
      return `${mm}/${dd}/${yyyy}`;
    case 'YYYY/M/D':
      return `${yyyy}/${m}/${d}`;
    default:
      return `${yyyy}/${mm}/${dd}`;
  }
};

export const getAllTranslations = (
  translate?: TranslationGetter,
): IDatePickerTranslations => {
  const getTranslation = (key: string, defaultTranslation: string) =>
    translate!('datePicker', `datePicker_${key}`, defaultTranslation);

  const defaultTranslations = Object.entries(DEFAULT_TRANSLATIONS).reduce<
    Partial<DefaultTranslations>
  >((translations, [key, defaultTranslation]) => {
    return {
      ...translations,
      [key]: getTranslation(key, defaultTranslation),
    };
  }, {}) as DefaultTranslations;

  const dayNames = DAY_NAMES.map(day => {
    const longName = day[0].toUpperCase() + day.slice(1);
    const shortName = longName.substr(0, 2);
    const longNameKey = `day_${day}_reg`;
    const shortNameKey = `day_${day}`;
    return {
      shortName: getTranslation(shortNameKey, shortName),
      longName: getTranslation(longNameKey, longName),
    };
  });

  const monthNames = MONTH_NAMES.map(month => {
    const longName = month[0].toUpperCase() + month.slice(1);
    const shortName = longName.substr(0, 3);
    const longNameKey = `month_${month}`;
    const shortNameKey = `month_${month}_min`;
    return {
      shortName: getTranslation(shortNameKey, shortName),
      longName: getTranslation(longNameKey, longName),
    };
  });

  return {
    ...defaultTranslations,
    dayNames,
    monthNames,
  };
};
