import * as React from 'react';
import { IDatePickerCalendarProps } from '../DatePickerCalendar.types';
import { keyCodes } from '../../../core/commons/a11y';
import { useClickOutside } from '../../../providers/useClickOutside/useClickOutside';
import style from './styles/DatePickerCalendar.scss';
import Month from './components/Month';
import Years from './components/Years';

const noop = () => {};

const Calendar: React.FC<IDatePickerCalendarProps> = props => {
  const {
    t,
    id,
    skin,
    value,
    minDate,
    maxDate,
    allowPastDates,
    allowFutureDates,
    disabledDates,
    disabledDaysOfWeek,
    weekStartDay,
    isCompactMode,
    inputWrapperRef,
    onApply = noop,
    onCancel = noop,
    onClick = noop,
    onDblClick = noop,
    NavbarComponent,
    onMouseEnter = noop,
    onMouseLeave = noop,
  } = props;
  const calendarRef = React.useRef<HTMLDivElement>(null);

  const onClickOutside = React.useCallback(() => {
    onCancel(false);
  }, [onCancel]);

  useClickOutside([calendarRef, inputWrapperRef], onClickOutside, true);

  const [isYearsMode, setYearsMode] = React.useState<boolean>(false);

  const onCurrentYearClick = () => {
    setYearsMode(!isYearsMode);
  };

  const [year, setYear] = React.useState<number>(0);
  const [month, setMonth] = React.useState<number>(0);

  React.useEffect(() => {
    const dateForMonthAndYear = value || new Date(Date.now());
    setYear(dateForMonthAndYear.getFullYear());
    setMonth(dateForMonthAndYear.getMonth());
  }, [value]);

  const onYearChange = (_year: number) => {
    setYear(_year);
  };

  const onMonthChange = (_month: number) => {
    if (_month < 0) {
      setYear(year - 1);
      setMonth(11);
    } else if (_month > 11) {
      setYear(year + 1);
      setMonth(0);
    } else {
      setMonth(_month);
    }
  };

  const onDayChange = (_day: number) => {
    onApply(new Date(year, month, _day));
  };

  const changeYearFromYearsMode = (_year: number) => {
    setYear(_year);
    setYearsMode(false);
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.keyCode === keyCodes.escape) {
      onCancel(true);
    }
  };

  return (
    <div
      data-testid="calendar"
      id={id}
      className={style.calendarWrapper}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={style.beforeCalendar}></div>
      <div ref={calendarRef} className={style.calendar}>
        <NavbarComponent
          t={t}
          skin={skin}
          isCompactMode={isCompactMode}
          year={year}
          month={month}
          onYearChange={onYearChange}
          onMonthChange={onMonthChange}
          onCurrentYearClick={onCurrentYearClick}
        ></NavbarComponent>
        {isYearsMode || (
          <Month
            t={t}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            allowPastDates={allowPastDates}
            allowFutureDates={allowFutureDates}
            disabledDates={disabledDates}
            disabledDaysOfWeek={disabledDaysOfWeek}
            weekStartDay={weekStartDay}
            year={year}
            month={month}
            onDayChange={onDayChange}
          ></Month>
        )}
        {isYearsMode && (
          <Years year={year} onYearChange={changeYearFromYearsMode}></Years>
        )}
      </div>
    </div>
  );
};

export default Calendar;
