import * as React from 'react';
import classNames from 'classnames';
import style from '../styles/Years.scss';
import { ICalendarYearsProps } from '../../DatePickerCalendar.types';
import { YEARS_LIST } from '../../constants';

const Years: React.FC<ICalendarYearsProps> = props => {
  const { year, onYearChange } = props;

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const selectedItemRef = React.useRef<HTMLLIElement>(null);

  React.useLayoutEffect(() => {
    if (wrapperRef.current && selectedItemRef.current) {
      wrapperRef.current.scrollTop =
        selectedItemRef.current.offsetTop -
        wrapperRef.current.offsetHeight / 2 +
        selectedItemRef.current.offsetHeight / 2;
    }
  }, []);

  const getYear = (_year: number) => {
    const isSelected = _year === year;
    return (
      <li
        key={_year}
        role="option"
        ref={isSelected ? selectedItemRef : null}
        aria-selected={isSelected}
        className={classNames({
          [style.selected]: isSelected,
        })}
        onClick={() => onYearChange(_year)}
      >
        <span>{_year}</span>
      </li>
    );
  };

  return (
    <div data-testid="years" ref={wrapperRef} className={style.years}>
      <ul role="listbox">{YEARS_LIST.map(getYear)}</ul>
    </div>
  );
};

export default Years;
